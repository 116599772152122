import type {MarketId} from '@smart/types';
import type {AemContent} from '../../lambda/aem/types';

export type BooleanToggle = 'true' | 'false';
interface FetchAemOptions {
  readonly marketId: MarketId;
  readonly mockResponseEnabled: BooleanToggle;
  readonly preview: BooleanToggle;
  readonly signal: AbortSignal;
}
export async function fetchAem(options: FetchAemOptions): Promise<AemContent> {
  const {marketId, mockResponseEnabled, preview, signal} = options;

  const envName = process.env.ENV_NAME;

  if (!envName) {
    throw Error(`Missing "envName"`);
  }

  const searchParams = new URLSearchParams({
    envName,
    marketId,
    mockResponseEnabled,
    preview,
  });

  const url = `${process.env.BASE_BFF_URL}/aem?${searchParams.toString()}`;
  const response = await fetch(url, {signal});

  if (!response.ok) {
    throw Error(`Could not fetch AEM content`);
  }

  return response.json() as Promise<AemContent>;
}
